<script setup>
	const route = useRoute();

	const isGardens = computed(() => route.name.includes('profiles-id-gardens'));
</script>

<template>
	<header class="xl:sticky top-0 z-40 text-white" :class="{'landscape:static': isGardens}">
		<div class="flex items-center justify-between p-4">
			<button class="lg:hidden flex items-center mr-2" form="toggle-menu" aria-label="Навигация">
				<svg-icon name="menu" width="32" height="32"></svg-icon>
				<svg-icon class="hidden" name="close" width="32" height="32"></svg-icon>
			</button>
			<nuxt-link to="/" class="flex items-center text-2xl lg:mr-auto troboto">
				<img class="rounded-full mr-2 w-8 flex-shrink-0" src="@/assets/icons/uogorod-logo-48x48px.png" width="32" height="32" alt="Сообщество Умный Огород" />
				Умный огород
			</nuxt-link>
			<div class="flex">
				<slot name="end">
					<nuxt-link to="/auth/signin">
						<span><svg-icon class="align-middle" name="account-circle-outline-w" width="18" height="18"/></span> <span class="align-middle">Войти</span>
					</nuxt-link>
				</slot>
			</div>
		</div>
	</header>
</template>